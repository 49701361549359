body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Rabar";
  font-weight: bold;
  font-style: normal;
  src: url("assets/fonts/Rabar_038/Rabar_038.ttf"),
    url("assets/fonts/Rabar_038/Rabar_038.woff"),
    url("assets/fonts/Rabar_038/Rabar_038.woff2");
}

@font-face {
  font-family: "Rabar";
  font-weight: normal;
  font-style: normal;
  src: url("assets/fonts/Rabar_044/Rabar_044.ttf"),
    url("assets/fonts/Rabar_044/Rabar_044.woff"),
    url("assets/fonts/Rabar_044/Rabar_044.woff2");
}